import type { AuthenticationProps } from './Authentication';
import type { APISettingSchema } from '@readme/backend/models/apisetting/types';
import type { $TSFixMe } from '@readme/iso';
import type { HarRequest } from '@readme/oas-to-snippet/types';

import React, { useContext, useState, useMemo } from 'react';

import type { ProjectContextValue } from '@core/context';
import { ProjectContext, UserContext, VersionContext } from '@core/context';
import useMetrics from '@core/hooks/useMetrics';
import useReadmeApi from '@core/hooks/useReadmeApi';

import { useSecurityGroups } from '@ui/API/Auth/useSecurityGroups';
import Header from '@ui/API/Header';
import Response from '@ui/API/Response';
import Footer from '@ui/DocFooter';
import Flex from '@ui/Flex';
import HTTPStatus from '@ui/HTTPStatus';
import LanguagePicker from '@ui/LanguagePicker';
import RDMD from '@ui/RDMD';

import AuthContainer from '../components/AuthContainer';
import Playground, { PlaygroundSection } from '../components/Playground';
import RequestContainer from '../components/RequestContainer';
import { EphemeralHARContext } from '../context/HARContext';
import '../style.scss';

import InfoTable from './components/Info';
import RealtimeTable from './components/RealtimeTable';
import RequestsGraph from './components/RequestsGraph';
import classes from './style.module.scss';

interface SectionHeadingProps {
  number: string;
  title: string;
}

const SectionHeading = ({ number, title }: SectionHeadingProps) => {
  return (
    <h3 className={classes.SectionHeading}>
      <span className={classes['SectionHeading-stepnumber']}>{number}</span>
      <span className={classes['SectionHeading-title']}>{title}</span>
    </h3>
  );
};

interface GettingStartedProps extends AuthenticationProps {
  myRequestsEnabled: boolean;
  operationParams: $TSFixMe;
}

/**
 * The Playground section of <GettingStarted /> is mostly copied from <Reference /> and its various children:
 * https://github.com/readmeio/readme/blob/next/packages/react/src/routes/Reference/index.jsx
 * */

const GettingStarted = ({
  doc,
  inputRef,
  myRequestsEnabled,
  oas,
  oasPublicUrl,
  oauth,
  onError,
  operation,
  operationParams,
  requestsEnabled,
  sidebar,
}: GettingStartedProps) => {
  const { project } = useContext(ProjectContext) as ProjectContextValue;
  const { version } = useContext(VersionContext);
  const { user } = useContext(UserContext) || {};

  const { clearEphemeralHAR, ephemeralHAR, setEphemeralHAR } = useContext(EphemeralHARContext);
  const [selectedHar, setSelectedHar] = useState<HarRequest | null | undefined>(null);

  const { securityGroups } = useSecurityGroups(operation, []);
  const hasSecurity = securityGroups.length;

  const { data, error, isLoading } = useReadmeApi<APISettingSchema['info'][]>('api/realtime/openapi-info');

  const { isDevDashEnabled } = useMetrics();

  const MyRequests = useMemo(() => (user ? <RequestsGraph ephemeralHAR={ephemeralHAR} /> : null), [user, ephemeralHAR]);

  const TopEndpoints = useMemo(() => {
    const columns = ['path', user && 'total', user && 'spark'].filter(name => name != null) as string[];
    const filters = [{ label: isDevDashEnabled ? 'Last 30 Days' : 'Last 24 Hours' }];
    const headings = [user ? 'My Top Endpoints' : 'Popular Endpoints'];

    return (
      <RealtimeTable
        columnNames={columns}
        ephemeralHAR={ephemeralHAR}
        filters={filters}
        headings={headings}
        isDevDashEnabled={isDevDashEnabled}
        metricsUrl="requests/top-endpoints"
        type="topEndpoints"
      />
    );
  }, [user, ephemeralHAR, isDevDashEnabled]);

  const RecentRequests = useMemo(() => {
    const columns = ['status', 'path', 'time'];
    const filters = [{ label: 'All Requests' }, { label: '400 & 500', prefix: <HTTPStatus iconOnly status="4XX" /> }];
    const headings = ['My Recent Requests', 'My Recent Errors'];
    const actions = myRequestsEnabled
      ? [
          { label: 'More Requests', nav: '/reference/intro/my-requests' },
          { label: 'More Errors', nav: '/reference/intro/my-requests' }, // TODO, support multiple HTTP status code queryparams in /logs handlers
        ]
      : [];

    if (!user) return null;

    return (
      <RealtimeTable
        actions={actions}
        columnNames={columns}
        ephemeralHAR={ephemeralHAR}
        filters={filters}
        headings={headings}
        isDevDashEnabled={isDevDashEnabled}
        metricsUrl="requests/list"
        type="recentRequests"
      />
    );
  }, [isDevDashEnabled, myRequestsEnabled, ephemeralHAR, user]);

  return (
    <article className={classes['GettingStarted-Wrapper']} id="content">
      <div className="rm-Article">
        <Header doc={doc} oas={oas} sidebar={sidebar} />
        <Playground className={classes['GettingStarted-Playground']} id="ReferencePlayground">
          <PlaygroundSection className={classes['GettingStarted-Playground-Section']}>
            <SectionHeading number="1" title="Pick a language" />
            <LanguagePicker />
          </PlaygroundSection>
          {!!hasSecurity && (
            <PlaygroundSection className={classes['GettingStarted-Playground-Section']}>
              <SectionHeading number="2" title="Authenticate" />
              <AuthContainer
                apiDefinition={oas}
                customLoginEnabled={!!project.oauth_url}
                hasLegacyUI={false}
                inputRef={inputRef}
                oauth={oauth}
                operation={operation}
                setSelectedHar={setSelectedHar}
              />
            </PlaygroundSection>
          )}
          <PlaygroundSection className={classes['GettingStarted-Playground-Section']}>
            <SectionHeading number={hasSecurity ? '3' : '2'} title="Try it!" />
            <div className={classes['GettingStarted-Playground-Section-Params']}>{operationParams}</div>
            <RequestContainer
              apiDefinition={oas}
              har={selectedHar}
              onError={onError}
              operation={operation}
              requestsEnabled={requestsEnabled}
              setResponseHAR={setEphemeralHAR}
              url={oasPublicUrl}
            />
            <Response
              apiDefinition={oas}
              har={selectedHar || ephemeralHAR || null}
              onExampleRemove={clearEphemeralHAR}
              operation={operation}
              requestsEnabled={requestsEnabled}
              setSelectedHar={setSelectedHar}
            />

            {!!data && !!data.length && <InfoTable error={error} info={data} isLoading={isLoading} />}
          </PlaygroundSection>
        </Playground>
        {!!doc?.body && <RDMD key={doc.slug} body={doc.body} />}
      </div>
      <div className={classes['GettingStarted-Sidebar']}>
        <Flex align="stretch" gap="md" layout="col">
          {TopEndpoints}
          {MyRequests}
          {RecentRequests}
        </Flex>
      </div>
      <div className={classes['GettingStarted-Footer']}>
        <Footer doc={doc} project={project} version={version} />
      </div>
    </article>
  );
};

export default GettingStarted;
